import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "../landing/LandingPage.css";
import PsyderBanner from "../../assets/imgs/psyder-banner.webp";
import PsyderBannerFooter from "../../assets/imgs/psyder-banner-footer.webp";

function TermsOfUseHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Psyder - Términos y Condiciones de Uso</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function TermsOfUse() {
  return (
    <TermsOfUseHelmet>
      <section className="info-pages">
        <div className="wrapper-banner">
          <img src={PsyderBanner} alt="Psyder Banner" className="img-fluid" />
        </div>
        <Container>
          <Row className="section-title">
            <Col>
              <h2>Térmiones y Condiciones de Uso</h2>
            </Col>
          </Row>
          <Row className="section-content">
            <Col>
              <p>
                Los presentes Términos y Condiciones regulan el acceso y la utilización del sitio web www.psyder.xyz (en adelante, el "Sitio Web"), propiedad de <strong>Sergio Villarroya Sanz</strong>{" "}
                (en adelante, el “Titular”), con DNI <strong>73105286-Q</strong>, y domicilio en <strong>Av. Teresa Salvo, 1, 44600 Alcañiz (Teruel)</strong>.
              </p>
              <p>
                Al acceder o utilizar el Sitio Web, el usuario acepta quedar vinculado por estos Términos y Condiciones, así como por nuestra Política de Privacidad y Política de Cookies. Si el
                usuario no está de acuerdo con alguna de estas condiciones, debe abstenerse de utilizar el Sitio Web.
              </p>

              <h3>1. Objeto del sitio web</h3>
              <p>
                El Sitio Web tiene como objetivo proporcionar información sobre los productos y servicios ofrecidos por <strong>Psyder</strong>, así como captar leads mediante formularios de contacto,
                suscripciones a newsletters y la interacción a través de widgets de chat en vivo.
              </p>
              <p>
                <strong>Psyder</strong> ofrece servicios relacionados con la gestión de la productividad y tecnología, tales como herramientas de análisis de datos, servicios de hosting y comunicación
                con usuarios. La información presentada en el Sitio Web es de carácter informativo y promocional.
              </p>

              <h3>2. Acceso y uso del Sitio Web</h3>
              <p>
                El acceso al Sitio Web es gratuito, si bien algunas de sus funcionalidades pueden estar condicionadas al registro del usuario o la aceptación de políticas adicionales. El acceso y la
                navegación por el Sitio Web no requieren registro, aunque ciertos servicios como la suscripción a newsletters o el envío de consultas a través del formulario de contacto sí pueden
                requerir el suministro de datos personales.
              </p>
              <p>
                El usuario se compromete a utilizar el Sitio Web de manera diligente y responsable, con pleno respeto a la legislación vigente, los buenos usos comerciales y la moral. El acceso a
                ciertas áreas o funcionalidades del Sitio Web puede estar restringido a mayores de 18 años o a usuarios que otorguen su consentimiento expreso para la recogida y tratamiento de datos.
              </p>

              <h3>3. Registro de usuarios y suscripciones</h3>
              <p>
                Al suscribirse a la newsletter o registrarse en el Sitio Web, el usuario garantiza que toda la información proporcionada es veraz, actualizada y completa. El usuario será responsable
                de mantener sus datos actualizados y de preservar la confidencialidad de sus credenciales de acceso, si aplica.
              </p>
              <p>
                <strong>Psyder</strong> se reserva el derecho a restringir el acceso a aquellos usuarios que incumplan estas condiciones, sin perjuicio de las acciones legales que pudieran derivarse.
              </p>

              <h3>4. Propiedad intelectual e industrial</h3>
              <p>
                Todo el contenido del Sitio Web, incluidos, entre otros, textos, imágenes, gráficos, logotipos, iconos, botones, software, nombres comerciales, marcas, diseños industriales o cualquier
                otro signo susceptible de uso industrial y comercial, está protegido por los derechos de propiedad intelectual e industrial del Titular o de terceros que han autorizado su uso.
              </p>
              <p>
                Queda estrictamente prohibida la reproducción, distribución, comunicación pública, transformación o cualquier otra forma de explotación sin el consentimiento previo y por escrito del
                Titular. El usuario puede visualizar los contenidos del Sitio Web para su uso personal y no comercial.
              </p>

              <h3>5. Enlaces a terceros</h3>
              <p>
                El Sitio Web puede contener enlaces a sitios web de terceros. Psyder no se responsabiliza de los contenidos, productos o servicios que puedan ofrecerse en dichos sitios web, ni de su
                correcto funcionamiento. El acceso a sitios web de terceros se realiza bajo la exclusiva responsabilidad del usuario.
              </p>
              <p>
                Asimismo, <strong>Psyder</strong> no se hace responsable de los daños que puedan derivarse de la interacción del usuario con enlaces de terceros, y recomienda la lectura de los
                términos y condiciones y las políticas de privacidad de cada sitio web al que se acceda.
              </p>

              <h3>6. Responsabilidad del Titular</h3>
              <p>
                El Titular se compromete a realizar todos los esfuerzos razonables para garantizar el correcto funcionamiento del Sitio Web y la actualización de los contenidos publicados. No
                obstante, <strong>Psyder</strong> no garantiza la disponibilidad continua del Sitio Web ni la ausencia de errores en los contenidos, ni se hace responsable de los daños que pudieran
                derivarse de la utilización de información o servicios ofrecidos a través del Sitio Web.
              </p>
              <p>En caso de interrupciones temporales o permanentes del servicio, el Titular se exime de cualquier responsabilidad, salvo cuando las circunstancias lo permitan legalmente.</p>

              <h3>7. Exoneración de responsabilidad</h3>
              <p>
                El uso del Sitio Web se realiza bajo la exclusiva responsabilidad del usuario. <strong>Psyder</strong> no será responsable de los daños o perjuicios que puedan derivarse de:
              </p>
              <ul>
                <li>La presencia de virus o programas maliciosos que puedan afectar a los dispositivos del usuario.</li>
                <li>Los errores, omisiones o inexactitudes en los contenidos del Sitio Web.</li>
                <li>La interrupción del servicio por motivos técnicos o de mantenimiento.</li>
                <li>La vulneración de la privacidad o seguridad derivada del uso de herramientas de terceros en el Sitio Web.</li>
              </ul>

              <h3>8. Modificaciones de los Términos y Condiciones</h3>
              <p>
                El Titular se reserva el derecho de modificar, en cualquier momento y sin previo aviso, los presentes Términos y Condiciones, publicando dichas modificaciones en el Sitio Web. Las
                modificaciones entrarán en vigor desde el momento de su publicación y serán aplicables a todos los usuarios desde ese momento.
              </p>
              <p>Se recomienda al usuario revisar estos Términos y Condiciones de forma periódica para estar al tanto de los cambios que puedan realizarse.</p>

              <h3>9. Protección de datos</h3>
              <p>
                El tratamiento de los datos personales recabados a través del Sitio Web se realizará de acuerdo con nuestra{" "}
                <strong>
                  <a href="https://www.iubenda.com/privacy-policy/98580626/full-legal">Política de Privacidad</a>
                </strong>
                , conforme a lo dispuesto por el <strong>Reglamento General de Protección de Datos (RGPD)</strong> y la{" "}
                <strong>Ley Orgánica de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD)</strong>.
              </p>
              <p>
                Los usuarios pueden ejercer sus derechos de acceso, rectificación, cancelación, oposición, limitación del tratamiento y portabilidad en los términos establecidos en la normativa
                vigente, contactando con el Titular a través de <a href="mailto:conecta@psyder.xyz">conecta@psyder.xyz.</a>
              </p>

              <h3>10. Legislación aplicable y jurisdicción</h3>
              <p>
                Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo con la legislación española. Para cualquier controversia que pudiera derivarse del acceso o uso del Sitio
                Web, las partes se someterán a los juzgados y tribunales de Teruel, renunciando a cualquier otro fuero que pudiera corresponderles.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="wrapper-banner">
          <img src={PsyderBannerFooter} alt="Psyder Banner" className="img-fluid banner-footer" />
        </div>
      </section>
    </TermsOfUseHelmet>
  );
}

export default TermsOfUse;
