import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import NavigationBar from "./components/common/NavigationBar/NavigationBar";
import LandingPage from "./components/landing/LandingPage";
import AboutPsyder from "./components/infoPages/AboutPsyder";
import HowItWorks from "./components/infoPages/HowItWorks";
import Legal from "./components/legal/Legal";
import TermsOfUse from "./components/legal/TermsOfUse";
import Footer from "./components/common/Footer/Footer";

import "./assets/styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const AppTemplate = () => (
  <>
    <header>
      <NavigationBar />
    </header>
    <main>
      <Outlet />
    </main>
    <footer>
      <Footer />
    </footer>
  </>
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppTemplate />}>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/sobre-psyder" element={<AboutPsyder />} />
          <Route path="/como-funciona" element={<HowItWorks />} />
          <Route path="/aviso-legal" element={<Legal />} />
          <Route path="/terminos-condiciones" element={<TermsOfUse />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
