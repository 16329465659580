import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LandingDivider from "../common/Dividers/LandingDivider";
import Hero from "./Hero/Hero";
import Mission from "./Mission/Mission";
import Patients from "./Patients/Patients";
import Professionals from "./Professionals/Professionals";
import Pricing from "./Pricing/Pricing";
import Testimonials from "./Testimonials/Testimonials";
import Contact from "./Contact/Contact";
import "./LandingPage.css";

function LandingPage() {
  const location = useLocation();
  
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.replace("#", "");
      const section = document.getElementById(sectionId);
      console.log(section);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <section className="section-hero">
        <Hero />
      </section>
      <section id="mision">
        <Mission />
      </section>
      <LandingDivider />
      <section id="para-empezar-terapia">
        <Patients />
      </section>
      <LandingDivider />
      <section id="para-psicoterapeutas">
        <Professionals />
      </section>
      <LandingDivider />
      <section id="precios">
        <Pricing />
      </section>
      <section id="contacto">
        <Contact />
      </section>
    </>
  );
}

export default LandingPage;
