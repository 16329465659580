import React, { useState } from "react";
import { Container, Row, Col, Button, Card, ToggleButtonGroup, ToggleButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

function Pricing() {
  const [billingType, setBillingType] = useState("Mensual");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.text}
    </Tooltip>
  );

  const pricingPlans = [
    {
      id: 1,
      title: "Plan Base",
      monthlyOriginalPrice: "39 €/mes",
      monthlyPrice: "30 €/mes",
      annualOriginalPrice: "390 €/año",
      annualPrice: "300 €/año",
      discount: "¡23% de Descuento!",
      summary: "Ideal para profesionales que buscan una solución completa y accesible para desarrollarse como psicoteraputas",
      description: (
        <ul>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Gestión de perfil con toda la información relevante y necesaria para mostrar cómo puedes ayudar" })}>
              <span>Perfil Profesional Completo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Formar parte de la base de datos y disponible para ofrecerte como la mejor opción" })}>
              <span>Aparición en Resultados <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Interacción confidencial a través de sistema de mensajería interno" })}>
              <span>Comunicación Privada y Segura con Pacientes <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Consulta de los resultados de la valoración inicial y datos de contacto" })}>
              <span>Acceso a Datos Relevantes del Paciente con su Permiso <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Organiza y gestiona citas directamente. Los pacientes podrán reservar citas" })}>
              <span>Agenda de Citas <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Facilidad de pagos con métodos rápidos y seguros como Stripe o PayPal" })}>
              <span>Pago Seguro para Pacientes <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Visitas al perfil, Nº de citas agendadas, reseñas, visualizaciones de artículos,…" })}>
              <span>Estadísticas del Perfil <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>3 Consultas al mes de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}>
              <span>2 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}>
              <span>1 Consulta al mes de Asesoramiento Profesional Individualizado (por videollamada) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "De entre todos los Grupos, podrás formar parte de 1 en el que compartir conocimiento con más profesionales, recibir feedback, y más" })}
            >
              <span>Acceso y Participación en 1 Grupo de Trabajo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Materiales gratuitos que poder utilizar en las terapias y opción de adquirir formación continua a precios asequibles" })}>
              <span>Acceso a Recursos Gratuitos y Formaciones con Suplemento <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
    {
      id: 2,
      title: "Plan Estándar",
      monthlyOriginalPrice: "59 €/mes",
      monthlyPrice: "43 €/mes",
      annualOriginalPrice: "590 €/año",
      annualPrice: "430 €/año",
      discount: "¡27% de Descuento!",
      summary: "Para profesionales que buscan un mayor acceso a recursos, con más oportunidades de formación y revisión de casos",
      description: (
        <ul>
          <li>Todo lo Incluido en el Plan Base</li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>7 Consultas al mes de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}>
              <span>2 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Realiza una revisión en profundidad casos con especialistas en la materia" })}>
              <span>1 Revisión de Casos por Videollamada con un Especialista <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}>
              <span>1 Consulta al mes de Asesoramiento Profesional Individualizado (videollamada) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({
                text: "De entre todos los Grupos, podrás formar parte de 3 en los que compartir conocimiento con más profesionales, recibir feedback, y más. También podrás leer el contenido de otros Grupos",
              })}
            >
              <span>Participación en 3 Grupos de Trabajo y Acceso a Consultar el resto <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({
                text: "Materiales gratuitos que poder utilizar en las terapias, formación continua incluida en el plan y opción de adquirir formación avanzada a precios asequibles",
              })}
            >
              <span>Acceso a Recursos y Formaciones Gratuitas, con Suplemento para Cursos Avanzados <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Posibilidad de compartir artículos previa revisión" })}>
              <span>Publicación Ilimitada de Artículos en el Blog de Psyder <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Se están preparando más herramientas y recursos con los que seguir mejorando y podrás tener acceso a ellas a precios muy asequibles" })}
            >
              <span>Acceso a Futuras Funcionalidades Avanzadas (con suplemento) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
    {
      id: 3,
      title: "Plan Premium",
      monthlyOriginalPrice: "119 €/mes",
      monthlyPrice: "83 €/mes",
      annualOriginalPrice: "1190 €/año",
      annualPrice: "830 €/año",
      discount: "¡30% de Descuento!",
      summary: "Para psicoterapeutas que buscan una experiencia completa y llevar su profesión al siguiente nivel",
      description: (
        <ul>
          <li>Todo lo Incluido en los Planes Base y Estándar</li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>Consultas Ilimitadas de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}
            >
              <span>4 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Realiza una revisión en profundidad casos con especialistas en la materia" })}
            >
              <span>2 Revisiones de Casos por Videollamada con Especialista <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}
            >
              <span>2 Consultas al mes de Asesoramiento Profesional Individualizado por Videollamada <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Podrás formar parte de todos los Grupos, en los que compartir conocimiento con más profesionales, recibir feedback, y más" })}
            >
              <span>Participación en Todos los Grupos de Trabajo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Materiales que poder utilizar en las terapias y formación continua avanzada" })}
            >
              <span>Acceso Completo a Todos los Recursos y Formaciones <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Se están preparando más herramientas y recursos con los que seguir mejorando y podrás tener acceso a ellas sin gastar más" })}
            >
              <span>Acceso Completo y Sin Costo Adicional a Futuras Funcionalidades Avanzadas <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Únete a la Evolución de la Psicoterapia con Psyder</h2>
          <h3>
            Regístrate Ahora y Aprovecha la <span className="prim-color">Oferta Pre-Lanzamiento Para Siempre</span>. Inscríbete y disfruta de una <span className="prim-color">Prueba Gratuita</span> de
            7 días desde el momento del Lanzamiento.
          </h3>
        </Col>
      </Row>
      <Row className="section-content justify-content-center">
        <Col md={5}>
          <Row>
            <Col className="d-flex align-items-end justify-content-end">
              <p className="prim-color mb-0">¡Ahorra 2 meses!</p>
            </Col>
          </Row>
          <ToggleButtonGroup type="radio" name="billingOptions" value={billingType} onChange={(value) => setBillingType(value)} className="pricing-toggle w-100 border rounded-pill">
            <ToggleButton id="tbg-radio-1" value="Mensual" className={`pricing-toggle fw-bold w-50 border-0 rounded-pill ${billingType === "Mensual" ? "active" : ""}`}>
              Mensual
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Anual" className={`pricing-toggle fw-bold w-50 border-0 rounded-pill ${billingType === "Anual" ? "active" : ""}`}>
              Anual
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row className="section-content">
        {pricingPlans.map((plan) => (
          <Col key={plan.id} lg={4} md={6} className="mb-4">
            <Card className="pricing-card h-100">
              <Card.Body className="text-center">
                <h4 className="mb-4">{plan.title}</h4>
                <Card.Text>
                  {billingType === "Mensual" ? (
                    <>
                      <del>{plan.monthlyOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.monthlyPrice}</span>
                    </>
                  ) : (
                    <>
                      <del>{plan.annualOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.annualPrice}</span>
                    </>
                  )}
                </Card.Text>
                <span className="third-color fw-bold d-block my-2">{plan.discount}</span>
                <p className="my-4">{plan.summary}</p>
                <Button href="#contacto" className="btn-prim my-3">
                  Únete Ahora
                </Button>
                <div className="mt-4 text-start">{plan.description}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="section-content justify-content-center">
        <Col lg={8} className="text-center">
          <p>
            Psyder está <strong>a punto de lanzarse</strong> y estamos preparando <strong>todo para ti</strong>. Cada plan está diseñado para ofrecerte <strong>flexibilidad</strong> y los{" "}
            <strong>recursos</strong> que necesites, tanto si estás comenzando como si ya tienes una carrera consolidada. Disfruta de <strong>soporte</strong> continuo, <strong>conexiones</strong>{" "}
            significativas y oportunidades de <strong>crecimiento profesional</strong>, todo en un solo lugar.
          </p>
          <Button className="btn btn-prim mt-3" href="/#contacto">
            Suscríbete a la Newsletter para Enterarte del Lanzamiento y Empezar tu Prueba Gratuita
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Pricing;
